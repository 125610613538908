body {
	margin: 0;
	padding: 0;
	overflow-x: hidden; /* Disable horizontal scrolling */
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.homeBanner {
	width: 100%;
	object-fit: contain;
	background-repeat: no-repeat;

	position: relative;
}
.homeBannerimg {
	/* height: 50vh; */
	width: 100% !important;
	/* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */
}
.home-slider {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.hero-slider-1 {
	width: 100%;
	overflow: hidden;
	height: 100%;
}

.single-hero-slider {
	width: 100%;
	display: flex;
	justify-content: center;
}
